import React from 'react';
import {Row,Col,Form, Button, Spinner} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ComTabEnergyChart from './ComTabEnergyChart';
import { LightningFill, CloudArrowUp } from 'react-bootstrap-icons';
import axios from 'axios';

class ComAdmin extends React.Component
{
    constructor(props) {
        super(props);
        this.state = 
        {
            requestSending : false,
        };
      }

    render()
    {
        return (
            <React.Fragment>
                <h1 style={{textAlign: 'left'}}>Lista użytkowników</h1>
                <br></br>
                <h1 style={{textAlign: 'left'}}>Dodaj użytkownika</h1>
                <br></br>
                <h1 style={{textAlign: 'left'}}>Zmień hasło użytkownika</h1>
                <br></br>
                <h1 style={{textAlign: 'left'}}>Lista przypisań</h1>
                <br></br>
                <h1 style={{textAlign: 'left'}}>Zmień limity</h1>
                <br></br>
                <h1 style={{textAlign: 'left'}}>Lista mieszkań</h1>
                <br></br>
                <h1 style={{textAlign: 'left'}}>Dodaj mieszkanie</h1>
                <br></br>

            </React.Fragment>
        );
    }
}

export default ComAdmin;