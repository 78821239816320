import React from 'react';
import {Row,Col,ProgressBar,Form,InputGroup, Button, Spinner} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ComTabResourcesEnvironmentChart from './ComTabResourcesEnvironmentChart';
import ComTabResourcesWaterChart from './ComTabResourcesWaterChart';
import { DropletHalf, CloudArrowUp } from 'react-bootstrap-icons';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import axios from 'axios';

class ComTabResources extends React.Component
{
    constructor(props) {
        super(props);
        this.state = 
        {
            requestSending : false,
            output1Visible : true,
            output2Visible : true,
            output3Visible : true,
            output4Visible : true,
            output5Visible : true,
            checkedOption : 1,
            temp_set : this.props.choosenHouse.temp_set,
            temp_on : this.props.choosenHouse.temp_on,
            freeze_protect : this.props.choosenHouse.freeze_protect,
        };
      }

    sumWater = () => 
    {
        //var sum = 0;
        //this.props.chartWater.wodaPitna1.map(({x,y}) => sum+=y);
        //this.props.chartWater.wodaPitna2.map(({x,y}) => sum+=y);
        var sum1 = 0;
        var sum2 = 0;
        try 
        {
            sum1 = this.props.chartWater.wodaPitna1[0].y - this.props.chartWater.wodaPitna1[this.props.chartWater.wodaPitna1.length-1].y;
            sum2 = this.props.chartWater.wodaPitna2[0].y - this.props.chartWater.wodaPitna2[this.props.chartWater.wodaPitna2.length-1].y;
        } 
        catch (error) 
        {
            
        }
        return ((sum1 + sum2)*60);
    }
      

    render()
    {
        let chartMulti;
        if (this.state.checkedOption===1)
        {
            chartMulti = <ComTabResourcesEnvironmentChart chartLabel={"Temperatura"} chartData={this.props.chartTemp}/>;
        } 
        else if (this.state.checkedOption===2)
        {
            chartMulti = <ComTabResourcesEnvironmentChart chartLabel={"Ciśnienie"} chartData={this.props.chartPress}/>;
        }
        else
        {
            chartMulti = <ComTabResourcesEnvironmentChart chartLabel={"Wilgotność"} chartData={this.props.chartHumid}/>;
        }

        return (
            <React.Fragment>
                
                <h1 style={{textAlign: 'left'}}>Płyny</h1>
                <br></br>
                <Row >
                    <Col md={6}>
                        <Row>
                            <Col md={3}>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" defaultChecked={this.state.output1Visible} label="Woda pitna 1" onChange={() => this.setState(prevState=> ({output1Visible:!prevState.output1Visible}))}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <ProgressBar animated  variant={this.props.actualValues.wodapitna1 <= 15 ? "danger" : (this.props.actualValues.wodapitna1 < 40 ? "warning" : "")} now={this.props.actualValues.wodapitna1} label={this.props.actualValues.wodapitna1 >= 5 ? (this.props.actualValues.wodapitna1 + "%") : ""} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" defaultChecked={this.state.output2Visible} label="Woda pitna 2" onChange={() => this.setState(prevState=> ({output2Visible:!prevState.output2Visible}))}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <ProgressBar animated variant={this.props.actualValues.wodapitna2 <= 15 ? "danger" : (this.props.actualValues.wodapitna2 < 40 ? "warning" : "")} now={this.props.actualValues.wodapitna2} label={this.props.actualValues.wodapitna2 >= 5 ? (this.props.actualValues.wodapitna2 + "%") : ""}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" defaultChecked={this.state.output3Visible} label="Woda brudna" onChange={() => this.setState(prevState=> ({output3Visible:!prevState.output3Visible}))}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <ProgressBar animated variant={this.props.actualValues.wodabrudna <= 60 ? "" : (this.props.actualValues.wodabrudna < 85 ? "warning" : "danger")} now={this.props.actualValues.wodabrudna} label={this.props.actualValues.wodabrudna >= 5 ? (this.props.actualValues.wodabrudna + "%") : ""}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" defaultChecked={this.state.output4Visible} label="Szambo" onChange={() => this.setState(prevState=> ({output4Visible:!prevState.output4Visible}))}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <ProgressBar animated variant={this.props.actualValues.szambo <= 60 ? "" : (this.props.actualValues.szambo < 85 ? "warning" : "danger")} now={this.props.actualValues.szambo} label={this.props.actualValues.szambo >= 5 ? (this.props.actualValues.szambo + "%") : ""}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" defaultChecked={this.state.output5Visible} label="Paliwo" onChange={() => this.setState(prevState=> ({output5Visible:!prevState.output5Visible}))}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <ProgressBar animated variant={this.props.actualValues.paliwo <= 15 ? "danger" : (this.props.actualValues.paliwo < 40 ? "warning" : "")} now={this.props.actualValues.paliwo} label={this.props.actualValues.paliwo >= 5 ? (this.props.actualValues.paliwo + "%") : ""} />
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <br></br>
                                <h3 style={{textAlign: "center"}}><DropletHalf style={{ color:"rgb(13, 110, 253)"}}/> Całkowite zużycie wody: {this.sumWater()} l</h3>
                            </Col>
                        </Row>
                        
                    </Col>
                        
                    <Col>
                        <ComTabResourcesWaterChart 
                        chartWater={this.props.chartWater}
                        output1Visible={this.state.output1Visible}
                        output2Visible={this.state.output2Visible}
                        output3Visible={this.state.output3Visible}
                        output4Visible={this.state.output4Visible}
                        output5Visible={this.state.output5Visible}
                        />
                    </Col>
                </Row>
                <h1 style={{textAlign: 'left'}}>Temperatura 
                    <Form.Check type="switch" id="custom-switch" checked={this.props.choosenHouse.temp_on} onChange={(e) => 
                    {
                        this.props.changeChoosenHouseData("temp_on", e.target.checked);
                        //this.props.choosenHouse.temp_on = e.target.checked
                        //this.setState({temp_on: e.target.checked})
                    }
                    } inline/>
                </h1>
                <Row className="pb-4 pb-sm-4 pb-md-4 pb-lg-3 pb-xl-3">
                    <Col md={6}>
                    <Row>
                            <Col md={9}>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" checked={this.props.choosenHouse.freeze_protect} label="Ochrona przed zamarzaniem" onChange={(e) => 
                                {
                                    this.props.changeChoosenHouseData("freeze_protect", e.target.checked);
                                    //this.props.choosenHouse.freeze_protect = e.target.checked;
                                    //this.setState({freeze_protect: e.target.checked});
                                }
                            }/>
                            </Form.Group>
                            </Col>
                            <Col md={3}> 
                                <Button variant="primary" style={{width:"100%"}} disabled={this.state.requestSending} id="button-addon4" onClick={() => 
                                {
                                    this.setState({requestSending : true});
                                    var pretempdate = new Date();
                                    var proptempdate = format(parseISO(pretempdate.toISOString()), 'yyyy-MM-dd HH:mm:ss');
                                    console.log(this.props.choosenHouse);
                                    axios.post(`http://backend-seastead.red-electronics.pl/service/setTempData`, {house_id: this.props.choosenHouse.house_id, temp_on: this.props.choosenHouse.temp_on, freeze_protect : this.props.choosenHouse.freeze_protect, temp_set : this.props.choosenHouse.temp_set, tempdate : proptempdate}, {
                                        headers: {
                                            Authorization: localStorage.getItem('token'),
                                        }}).then(res => {if(res.data==="OK"){alert("Konfiguracja wysłana poprawnie"); this.setState({requestSending : false});}}).catch(err => {
                                        console.log(err);
                                        this.setState({requestSending : false});
                                        this.props.navigate('/login')
                                    });
                                }}>
                                {<Spinner animation="border" size="sm" variant="light" hidden={!this.state.requestSending}/>}
                                {(this.state.requestSending === false) ? "Zapisz " : " Wysyłanie... "}
                                <CloudArrowUp/>
                            </Button>
                            </Col>
                        </Row>
                    <Row>
                            <Col md={4}>
                                <p>Zadana</p>
                            </Col>
                            <Col md={5}>

                            </Col>
                            <Col md={3}> 
                                <InputGroup>
                                    <Form.Control id="1" type="number" value={this.props.choosenHouse.temp_set} disabled={this.props.choosenHouse.freeze_protect} onChange={(e) => 
                                    {
                                        this.props.changeChoosenHouseData("temp_set", e.target.valueAsNumber);
                                        //console.log(val.target);
                                        //this.props.choosenHouse.temp_set = val.target.valueAsNumber;
                                        //this.setState({temp_set:val.target.valueAsNumber})
                                    }
                                    }/>
                                    <InputGroup.Text>{"\xB0C"}</InputGroup.Text>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <p>Serwerownia</p>
                            </Col>
                            <Col md={5}>
                                
                            </Col>
                            <Col md={3}> 
                                <InputGroup>
                                    <Form.Control id="2" value={this.props.actualValues.temp1} disabled={true}/>
                                    <InputGroup.Text>{"\xB0C"}</InputGroup.Text>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <p>Pływaki</p>
                            </Col>
                            <Col md={5}>
                                
                            </Col>
                            <Col md={3}> 
                                <InputGroup>
                                    <Form.Control id="3" value={this.props.actualValues.temp2} disabled={true}/>
                                    <InputGroup.Text>{"\xB0C"}</InputGroup.Text>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <p>Taras</p>
                            </Col>
                            <Col md={5}>
                                
                            </Col>
                            <Col md={3}> 
                                <InputGroup>
                                <Form.Control id="4" value={this.props.actualValues.temp3} disabled={true}/>
                                    <InputGroup.Text>{"\xB0C"}</InputGroup.Text>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Col>

                    <Col>
                        {chartMulti}
                        <br></br>
                        <div className="d-flex justify-content-center">
                            
                            <Form.Check inline label="Temperatura" name="chartType" type={"radio"} defaultChecked={true} onClick={() => this.setState({checkedOption:1})}/>
                            <Form.Check inline label="Ciśnienie" name="chartType" type={"radio"} onClick={() => this.setState({checkedOption:2})}/>
                            <Form.Check inline label="Wilgotność" name="chartType" type={"radio"} onClick={() => this.setState({checkedOption:3})}/>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default ComTabResources;