import ComWorkspace from './ComWorkspace';
import ComNavbar from './ComNavbar';
import './ControlPanel.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import {Spinner} from 'react-bootstrap';

class ControlPanel extends React.Component {

  constructor() 
  {
    super();

    this.state = {
      requestSending: false,
      constAppVariables:
      {
        ready: false,
        
        choosenHouse:{house_id:"ładowanie...", rented:1, house_label: "ładowanie..."},
        tempChoosenHouse:{},
        date_start_view: "",
        date_end_view: "",
      },
      recvData: null,
      recvActualValues:
      {
        valdate: "Dla tego domu nie ma dostępnych aktualnych informacji",
        wodapitna1: 0,
        wodapitna2: 0,
        wodabrudna: 0,
        szambo: 0,
        paliwo: 0,
        temp1: 0,
        temp2: 0,
        temp3: 0,
      },
      user:
      {
        login: "",
        availableHouseList: 
        [
        ],
      },
      rentHistoryList: [],
    };

    this.callReloadData = this.callReloadData.bind(this);
    this.setDateStart = this.setDateStart.bind(this);
    this.setDateEnd = this.setDateEnd.bind(this);
    this.setHouse = this.setHouse.bind(this);
    this.getDateStart = this.getDateStart.bind(this);
    this.getDateEnd = this.getDateEnd.bind(this);
    this.setNotReady = this.setNotReady.bind(this);
    this.setRequestSending = this.setRequestSending.bind(this);
    this.changeChoosenHouseData = this.changeChoosenHouseData.bind(this);
  }

  date_start = "";
  date_end =  "";
  token = "";

  componentDidMount() 
  {
    document.body.style.background = "white";
    this.token = localStorage.getItem('token');
    //const date_start = "2022-01-01 00:00:00";
    //const date_end = "2022-01-31 00:00:00";
    console.log("username: " + localStorage.getItem('user'));
    axios.get('http://backend-seastead.red-electronics.pl/service/userInfo/?user=' + localStorage.getItem('user'), {
        headers: {
            Authorization: this.token,
        }})
    .then(res => 
      {
        const userData = res.data;
        this.setState({ user: userData})

        if(this.state.user.availableHouseList.length !== 0)
        {
          this.setState(prevState=> ({
            constAppVariables :
            {
              ...prevState.constAppVariables,
              choosenHouse: this.state.user.availableHouseList[0],
              tempChoosenHouse: this.state.user.availableHouseList[0],
            }
          }));
          axios.get('http://backend-seastead.red-electronics.pl/service/rentList/?h=' + this.state.constAppVariables.choosenHouse.house_id, {
            headers: {
                Authorization: this.token,
            }})
        .then(res => 
          {
            const rentData = res.data;
            this.setState({ rentHistoryList: rentData})
            if(rentData.length >= 1)
            {
              this.setDateStart(parseISO(rentData[0].date_start));
              this.setDateEnd(parseISO(rentData[0].date_end));
            }
            else
            {
              this.setDateStart(new Date());
              this.setDateEnd(new Date());
            }
            this.callReloadData()
          }).catch(err => {
            console.log(err);
            this.props.navigate('/login')
        })
        }
        else
        {
          this.setDateStart(new Date());
          this.setDateEnd(new Date());
        }

        }).catch(err => {
            console.log(err);
            this.props.navigate('/login')
        });
    
  }

  setNotReady()
  {
    this.setState(prevState=> ({
      constAppVariables :
      {
        ...prevState.constAppVariables,
        ready: false
      }
    }))
  }

  setRequestSending()
  {
    this.setState({requestSending: true});
  }

  reloadData(date_start, date_end)
  {
    if(this.state.user.availableHouseList.length !== 0 )
    {
    this.setState({requestSending: true});

    axios.get('http://backend-seastead.red-electronics.pl/service/userInfo/?user=' + localStorage.getItem('user'), {
        headers: {
            Authorization: this.token,
        }})
    .then(res => 
      {
        const userData = res.data;
        this.setState({ user: userData})

        axios.get('http://backend-seastead.red-electronics.pl/service/rentList/?h=' + this.state.constAppVariables.choosenHouse.house_id, {
            headers: {
                Authorization: this.token,
            }})
        .then(res => 
          {
            const rentData = res.data;
            this.setState({ rentHistoryList: rentData})

            axios.get('http://backend-seastead.red-electronics.pl/service/recvActualValues?h=' + this.state.constAppVariables.choosenHouse.house_id, {
                headers: {
                    Authorization: this.token,
                }})
            .then(res => 
              {
                const actualData = res.data[0];
                this.setState({ recvActualValues: actualData})

                axios.get('http://backend-seastead.red-electronics.pl/service/dataRequest?h=' + this.state.constAppVariables.choosenHouse.house_id + '&s=' + date_start + '&e=' + date_end, {
                    headers: {
                        Authorization: this.token,
                    }})
                .then(res => 
                  {
                    const houseData = res.data;
                    this.setState({ recvData: houseData});
                    console.log(houseData);
                    console.log(4);
                    this.setState(prevState=> ({
                      constAppVariables :
                      {
                        ...prevState.constAppVariables,
                        ready: true
                      }
                    }))
                    var varTempChoosenHouse = this.state.user.availableHouseList.filter( x => { return (x.house_id === this.state.constAppVariables.choosenHouse.house_id)});
                    this.setState(prevState=> ({
                      constAppVariables :
                      {
                        ...prevState.constAppVariables,
                        tempChoosenHouse: varTempChoosenHouse[0],
                      }
                    }), () => console.log(this.state.constAppVariables.tempChoosenHouse))
                  }).catch(err => {
                    console.log(err);
                    this.props.navigate('/login')
                });
              }).catch(err => {
                console.log(err);
                this.props.navigate('/login')
            });
          }).catch(err => {
            console.log(err);
            this.props.navigate('/login')
        });
      }).catch(err => {
        console.log(err);
        this.props.navigate('/login')
    });
    
    this.setState({requestSending: false});
  }
  else
  {
    this.setState(prevState=> ({
      constAppVariables :
      {
        ...prevState.constAppVariables,
        ready: true
      }
    }));
    alert("Nie można pobrać danych. Brak przypisanych domków.");
  }
    return true;
  }

  changeChoosenHouseData(name, val)
  {
    this.setState(prevState=> ({
      constAppVariables :
      {
        ...prevState.constAppVariables,
        tempChoosenHouse:
        {
          ...prevState.constAppVariables.tempChoosenHouse,
          [name]: val,
        }
      }
    }));
  }

  callReloadData()
  {
    return this.reloadData(this.date_start, this.date_end);
  }

  getDateStart()
  {
    return this.date_start;
  }

  getDateEnd()
  {
    return this.date_end;
  }

  setDateStart(val)
  {
    this.date_start = format(parseISO(val.toISOString()), 'yyyy-MM-dd HH:mm');
    this.setState(prevState=> ({
      constAppVariables :
      {
        ...prevState.constAppVariables,
        date_start_view: format(parseISO(val.toISOString()), 'dd.MM.yyyy HH:mm')
      }
    }))
    //document.querySelector('dateTimePicker1').value = item.date_start;
  }

  setDateEnd(val)
  {
    this.date_end = format(parseISO(val.toISOString()), 'yyyy-MM-dd HH:mm');
    this.setState(prevState=> ({
      constAppVariables :
      {
        ...prevState.constAppVariables,
        date_end_view: format(parseISO(val.toISOString()), 'dd.MM.yyyy HH:mm')
      }
    }))
  }

  setHouse(val,rnt,lbl)
  {
    this.setState(prevState=> ({
      constAppVariables :
      {
        ...prevState.constAppVariables,
        choosenHouse: 
        {
          ...prevState.constAppVariables.choosenHouse,
          house_id: val,
	        rented: rnt,
          house_label: lbl
        }
      }
    }), () => {
      this.callReloadData();
    })
  }

  changeDate = (date_start, date_end) =>
  {
    axios.get('http://backend-seastead.red-electronics.pl/service/dataRequest?h=' + this.state.constAppVariables.choosenHouse.house_id + '&s=' + date_start + '&e=' + date_end)
    .then(res => 
      {
        const houseData = res.data;
        this.setState({ recvData: houseData})
      });
  }

  render()
  {
    return (      
      <div className="App">
        {this.state.constAppVariables.ready === false ? <div style={{margin:"0", position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}} ><Spinner animation="border" variant="primary" /></div> : <>
        <ComNavbar
        navigate = {this.props.navigate}
        requestSending = {this.state.requestSending}
        setRequestSending = {this.setRequestSending}
        user = {this.state.user}       
        setHouse = {this.setHouse}
        setDateEnd = {this.setDateEnd}
        setDateStart = {this.setDateStart}
        callReloadData = {this.callReloadData}
        rentHistoryList = {this.state.rentHistoryList}
        choosenHouse = {this.state.constAppVariables.choosenHouse}
        getDateStart = {this.getDateStart}
        getDateEnd = {this.getDateEnd}
        date_start_view = {this.state.constAppVariables.date_start_view}
        date_end_view = {this.state.constAppVariables.date_end_view}
        actualValues = {this.state.recvActualValues}
        />
        <ComWorkspace 
        navigate = {this.props.navigate}
        callReloadData = {this.callReloadData}
        chartTemp = {this.state.recvData.chartTemp}
        actualValues = {this.state.recvActualValues}
        chartHumid = {this.state.recvData.chartHumid}
        chartPress = {this.state.recvData.chartPress}
        chartWater = {this.state.recvData.chartWater}
        rentHistoryList = {this.state.rentHistoryList}
        chartEnergy = {this.state.recvData.chartEnergy} 
        choosenHouse = {this.state.constAppVariables.tempChoosenHouse}
        constData = {{user: this.state.user.login, house_id: this.state.constAppVariables.choosenHouse.house_id}}
        changeChoosenHouseData ={this.changeChoosenHouseData}
        />
        </>}
      </div>
    );
  }
}

export default ControlPanel;