import React from 'react';
import {Row,Col,FormControl,Table,InputGroup,Button, OverlayTrigger, Popover, Spinner} from 'react-bootstrap';
import {Trash, CloudArrowUp} from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import 'moment/locale/pl';
import axios from 'axios';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import compareAsc from 'date-fns/compareAsc';

class ComTabNotifications extends React.Component
{
    state =
    {
        requestSending : false,
        enabledSend: false,

        number1 : this.props.choosenHouse.number1  || '',
        number2 : this.props.choosenHouse.number2  || '',
        number3 : this.props.choosenHouse.number3  || '',
    
        wodapitna1_notify_value : this.props.choosenHouse.wodapitna1_notify_value  || '',
        wodapitna2_notify_value : this.props.choosenHouse.wodapitna2_notify_value  || '',
        wodabrudna_notify_value : this.props.choosenHouse.wodabrudna_notify_value  || '',
        paliwo_notify_value : this.props.choosenHouse.paliwo_notify_value  || '',
        szambo_notify_value : this.props.choosenHouse.szambo_notify_value  || '',
    }

    //number1 = this.props.choosenHouse.number1;
    //number2 = this.props.choosenHouse.number2;
    //number3 = this.props.choosenHouse.number3;

    //wodapitna1_notify_value = this.props.choosenHouse.wodapitna1_notify_value;
    //wodapitna2_notify_value = this.props.choosenHouse.wodapitna2_notify_value;
    //wodabrudna_notify_value = this.props.choosenHouse.wodabrudna_notify_value;
    //paliwo_notify_value = this.props.choosenHouse.paliwo_notify_value;
    //szambo_notify_value = this.props.choosenHouse.szambo_notify_value;

    numberCorrect = (number) =>
    {
        var correct = true;
        if(number === "")
        {
            return true;
        }

        else
        {
            if(number.indexOf("+") !== -1)
            {
                for(let i=0; i<number.length; i++)
                {
                    var char = number.charAt(i);
                    if(char === '+' || char === '0' || char === '1' || char === '2'|| char === '3'|| char === '4'|| char === '5'|| char === '6'|| char === '7'|| char === '8'|| char === '9')
                        {
                            if(number.length >= 12)
                            {

                            }
                            else
                            correct = false;
                        }
                        else
                        {
                            correct = false;
                        }
                }
                return correct;
            }
            else
            {
                return false;
            }
        }
    }

    setNotifications = () =>
    {
        if(this.numberCorrect(this.props.choosenHouse.number1) && this.numberCorrect(this.props.choosenHouse.number2) && this.numberCorrect(this.props.choosenHouse.number3))
        {
            var Data = 
            {
                house_id: this.props.house_id.toString(), 
                number1: this.props.choosenHouse.number1.toString(),
                number2: this.props.choosenHouse.number2.toString(),
                number3: this.props.choosenHouse.number3.toString(), 
                wodapitna1_notify_value: this.props.choosenHouse.wodapitna1_notify_value.toString(),
                wodapitna2_notify_value: this.props.choosenHouse.wodapitna2_notify_value.toString(),
                wodabrudna_notify_value: this.props.choosenHouse.wodabrudna_notify_value.toString(),
                paliwo_notify_value: this.props.choosenHouse.paliwo_notify_value.toString(),
                szambo_notify_value: this.props.choosenHouse.szambo_notify_value.toString(),
            };
            this.setState({requestSending : true});
            this.setState({enabledSend: false});
            axios.post(`http://backend-seastead.red-electronics.pl/service/setNotifications`, Data, {
                headers: {
                    Authorization: localStorage.getItem('token'),
                }})
            .then(res => {
                this.setState({requestSending : false});
                this.setState({enabledSend: true});
                if(res.data==="OK")
                {
                    alert("Zmiany zostały zapisane");
                    this.props.callReloadData();
                }
            }).catch(err => {
                console.log(err);
                this.setState({requestSending : false});
                this.setState({enabledSend: true});
                this.props.navigate('/login')
            });
        }
        else
        {
            alert("Numer nie został wprowadzony poprawnie. Wymagany format to +(numer kierunkowy)(numer)");
        }
    }

/*     componentDidUpdate(prevProps){
        
        console.log(prevProps.choosenHouse.number1 + "?=" + this.props.choosenHouse.number1);

        if(prevProps.choosenHouse.number1 !== this.props.choosenHouse.number1){
            console.log(prevProps.choosenHouse.number1 + "!==" + this.props.choosenHouse.number1);
            console.log("prevState" + this.state.number1);
            this.setState({number1: this.props.choosenHouse.number1  || ''});
            console.log("state" + this.state.number1);
        }

        if(prevProps.choosenHouse.number2 !== this.props.choosenHouse.number2){
            this.setState({number2: this.props.choosenHouse.number2  || ''});
        }

        if(prevProps.choosenHouse.number3 !== this.props.choosenHouse.number3){
            this.setState({number3: this.props.choosenHouse.number3  || ''});
        }

        if(prevProps.choosenHouse.wodapitna1_notify_value !== this.props.choosenHouse.wodapitna1_notify_value){
            this.setState({wodapitna1_notify_value:this.props.choosenHouse.wodapitna1_notify_value  || ''});
        }

        if(prevProps.choosenHouse.wodapitna2_notify_value !== this.props.choosenHouse.wodapitna2_notify_value){
            this.setState({wodapitna2_notify_value:this.props.choosenHouse.wodapitna2_notify_value  || ''});
        }

        if(prevProps.choosenHouse.wodabrudna_notify_value !== this.props.choosenHouse.wodabrudna_notify_value){
            this.setState({wodabrudna_notify_value:this.props.choosenHouse.wodabrudna_notify_value  || ''});
        }

        if(prevProps.choosenHouse.paliwo_notify_value !== this.props.choosenHouse.paliwo_notify_value){
            this.setState({paliwo_notify_value:this.props.choosenHouse.paliwo_notify_value  || ''});
        }


        if(prevProps.choosenHouse.szambo_notify_value !== this.props.choosenHouse.szambo_notify_value){
            this.setState({szambo_notify_value:this.props.choosenHouse.szambo_notify_value  || ''});
        }

    } */

    render()
    {
        return (
            <React.Fragment>
                <h1 style={{textAlign: 'left'}}>Powiadomienia</h1>
                <br></br>
                <div>
                <Row>
                    <Col>
                    
                    <h3 style={{textAlign: 'left'}}>Numer 1</h3>
                    {/*                     <FormControl size="bg" id="1" type="text" value={this.state.number1} onChange={(temp) => {
                                    this.setState({number1: temp.target.value})
                    }}/> */}

                    {Object.keys(this.props.choosenHouse).length > 0 ? <FormControl size="bg" id="1" type="text" value={Object.keys(this.props.choosenHouse.number1).length > 0 ? this.props.choosenHouse.number1 : ""} onChange={(e) => 
                    {
                        this.props.changeChoosenHouseData("number1", e.target.value);
                        //this.props.choosenHouse.output1 = e.target.checked;
                    }}/> : <Spinner animation="border" variant="primary" />}

                    <h3 style={{textAlign: 'left'}}>Numer 2</h3>
{/*                     <FormControl size="bg" id="2" type="text" value={this.state.number2} onChange={(temp) => {
                                    this.setState({number2: temp.target.value})
                    }}/> */}
                    {Object.keys(this.props.choosenHouse).length > 0 ? <FormControl size="bg" id="2" type="text" value={Object.keys(this.props.choosenHouse.number2).length > 0 ? this.props.choosenHouse.number2 : ""} onChange={(e) => 
                    {
                        this.props.changeChoosenHouseData("number2", e.target.value);
                        //this.props.choosenHouse.output1 = e.target.checked;
                    }}/> : <Spinner animation="border" variant="primary" />}
                    <h3 style={{textAlign: 'left'}}>Numer 3</h3>
{/*                     <FormControl size="bg" id="3" type="text" value={this.state.number3} onChange={(temp) => {
                                    this.setState({number3: temp.target.value})
                    }}/> */}
                    {Object.keys(this.props.choosenHouse).length > 0 ? <FormControl size="bg" id="3" type="text" value={Object.keys(this.props.choosenHouse.number3).length > 0 ? this.props.choosenHouse.number3 : ""} onChange={(e) => 
                    {
                        this.props.changeChoosenHouseData("number3", e.target.value);
                        //this.props.choosenHouse.output1 = e.target.checked;
                    }}/> : <Spinner animation="border" variant="primary" />}
                    </Col>

                    <Col>
        
                    <h3 style={{textAlign: 'left'}}>Limit woda pitna 1 (min)</h3>
{/*                     <FormControl size="bg" id="4" type="number" value={this.state.wodapitna1_notify_value} onChange={(temp) => {
                                    this.setState({wodapitna1_notify_value: temp.target.value})
                    }}/> */}
                    {Object.keys(this.props.choosenHouse).length > 0 ? <FormControl size="bg" id="4" type="number" value={this.props.choosenHouse.wodapitna1_notify_value} onChange={(e) => 
                    {
                        this.props.changeChoosenHouseData("wodapitna1_notify_value", e.target.value);
                        //this.props.choosenHouse.output1 = e.target.checked;
                    }}/> : <Spinner animation="border" variant="primary" />}
                    <h3 style={{textAlign: 'left'}}>Limit woda pitna 2 (min)</h3>
{/*                     <FormControl size="bg" id="5" type="number" value={this.state.wodapitna2_notify_value} onChange={(temp) => {
                                    this.setState({wodapitna2_notify_value: temp.target.value}) 
                    }}/>*/}
                    {Object.keys(this.props.choosenHouse).length > 0 ? <FormControl size="bg" id="5" type="number" value={this.props.choosenHouse.wodapitna2_notify_value} onChange={(e) => 
                    {
                        this.props.changeChoosenHouseData("wodapitna2_notify_value", e.target.value);
                        //this.props.choosenHouse.output1 = e.target.checked;
                    }}/> : <Spinner animation="border" variant="primary" />}
                    <h3 style={{textAlign: 'left'}}>Limit woda brudna (max)</h3>
{/*                     <FormControl size="bg" id="6" type="number" value={this.state.wodabrudna_notify_value} onChange={(temp) => {
                                    this.setState({wodabrudna_notify_value: temp.target.value})
                    }}/> */}
                    {Object.keys(this.props.choosenHouse).length > 0 ? <FormControl size="bg" id="6" type="number" value={this.props.choosenHouse.wodabrudna_notify_value} onChange={(e) => 
                    {
                        this.props.changeChoosenHouseData("wodabrudna_notify_value", e.target.value);
                        //this.props.choosenHouse.output1 = e.target.checked;
                    }}/> : <Spinner animation="border" variant="primary" />}
                    <h3 style={{textAlign: 'left'}}>Limit paliwo (min)</h3>
{/*                     <FormControl size="bg" id="7" type="number" value={this.state.paliwo_notify_value} onChange={(temp) => {
                                    this.setState({paliwo_notify_value: temp.target.value})
                    }}/> */}
                    {Object.keys(this.props.choosenHouse).length > 0 ? <FormControl size="bg" id="7" type="number" value={this.props.choosenHouse.paliwo_notify_value} onChange={(e) => 
                    {
                        this.props.changeChoosenHouseData("paliwo_notify_value", e.target.value);
                        //this.props.choosenHouse.output1 = e.target.checked;
                    }}/> : <Spinner animation="border" variant="primary" />}
                    <h3 style={{textAlign: 'left'}}>Limit szambo (max)</h3>
{/*                     <FormControl size="bg" id="8" type="number" value={this.state.szambo_notify_value} onChange={(temp) => {
                                    this.setState({szambo_notify_value: temp.target.value})
                    }}/> */}
                    {Object.keys(this.props.choosenHouse).length > 0 ? <FormControl size="bg" id="8" type="number" value={this.props.choosenHouse.szambo_notify_value} onChange={(e) => 
                    {
                        this.props.changeChoosenHouseData("szambo_notify_value", e.target.value);
                        //this.props.choosenHouse.output1 = e.target.checked;
                    }}/> : <Spinner animation="border" variant="primary" />}
                    </Col>
                </Row>
                </div>
                <Button variant="primary" style={{textAlign: 'right'}} id="button-addon4" onClick={() => {this.setNotifications()}}>
                    {<Spinner animation="border" size="sm" variant="light" hidden={!this.state.requestSending}/>}
                    {(this.state.requestSending === false) ? "Zapisz zmiany " : " Wysyłanie... "}
                    <CloudArrowUp/>
                </Button>
            </React.Fragment>
        );
    }
}

export default ComTabNotifications;