import React, { useState, useEffect } from 'react'
import {Container, Form, Button, Navbar, Row, Col} from 'react-bootstrap';
import {ArrowLeft, CloudArrowUp} from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useNavigate } from 'react-router';

function Profil() {
    let navigate = useNavigate();
    const [oldpassword, setOldPassword] = useState('');
    const [newpassword, setNewPassword] = useState('');
    const [newrepeatpassword, setNewRepeatPassword] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get("http://backend-seastead.red-electronics.pl/service/protected", {
            headers: {
                Authorization: token,
            }
        }).then(res => {
            console.log(res);
            navigate('/profile');
        }).catch(err => {
            console.log(err);
            navigate('/login');
        })
    }, []); 

    const submit = () => {
        if((oldpassword !== "") && (newpassword !== "") && (newrepeatpassword !== ""))
        {
            if(newpassword === newrepeatpassword)
            {
                axios.post("http://backend-seastead.red-electronics.pl/service/chpass", { username: localStorage.getItem('user'), oldpassword, newpassword},{
                    headers: {
                        Authorization: localStorage.getItem('token'),
                    }}).then(user => {
                    console.log(user);
                    alert("Hasło zmienione pomyślnie.")
                    navigate('/');
                }).catch(err => {
                    console.log(err);
                    alert(err.response.data.message);
                });
            }
            else
            {
                alert("Hasła nie są identyczne.");
            }
        }
        else
        {
            alert("Nie wszystkie pola zostały wypełnione.");
        }
    }

    useEffect(() => { document.body.style.background = "white"}, [])
    return (
        <>
            <Navbar bg="primary" expand="lg">
                <Container fluid>
                    <Button variant="primary" onClick={() => { navigate('/')}}> <ArrowLeft size={35}/> </Button>
                </Container>
            </Navbar>
            <Container>
                <br></br>
                <h2>Zmiana hasła użytkownika</h2>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Stare hasło</Form.Label>
                                <Form.Control type="password" placeholder="Wprowadź stare hasło" value={oldpassword} onChange={event => setOldPassword(event.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Nowe hasło</Form.Label>
                                <Form.Control type="password" placeholder="Wprowadź nowe hasło" value={newpassword} onChange={event => setNewPassword(event.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Powtórz nowe hasło</Form.Label>
                                <Form.Control type="password" placeholder="Powtórz nowe hasło" value={newrepeatpassword} onChange={event => setNewRepeatPassword(event.target.value)}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br></br>
                    <Button variant="primary" onClick={submit}>Zapisz zmiany <CloudArrowUp/></Button>
            </Container>
            
        </>
    )
}

export default Profil
