import React from 'react';
import {Line} from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import 
{
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

class ComTabWaterChart extends React.Component 
{
  render() 
  {
    return (
      <div>
        <Line
          data={{  
            datasets: [
            {
              label: 'Woda pitna 1',
              fill: false,
              hidden: !this.props.output1Visible,
              backgroundColor: 'rgb(77, 201, 246)',
              borderColor: 'rgb(77, 201, 246)',
              borderWidth: 2,
              data: this.props.chartWater.wodaPitna1,
            },
            {
              label: 'Woda pitna 2',
              fill: false,
              hidden: !this.props.output2Visible,
              backgroundColor: 'rgb(13, 110, 253)',
              borderColor: 'rgb(13, 110, 253)',
              borderWidth: 2,
              data: this.props.chartWater.wodaPitna2,
            },
            {
              label: 'Woda brudna',
              fill: false,
              hidden: !this.props.output3Visible,
              backgroundColor: 'rgb(163, 163, 163)',
              borderColor: 'rgb(163, 163, 163)',
              borderWidth: 2,
              data: this.props.chartWater.wodaBrudna,
            },
            {
              label: 'Szambo',
              fill: false,
              hidden: !this.props.output4Visible,
              backgroundColor: 'rgb(170, 78, 12)',
              borderColor: 'rgb(170, 78, 12)',
              borderWidth: 2,
              data: this.props.chartWater.szambo,
            },
            {
              label: 'Paliwo',
              fill: false,
              hidden: !this.props.output5Visible,
              backgroundColor: 'rgb(0, 169, 80)',
              borderColor: 'rgb(0, 169, 80)',
              borderWidth: 2,
              data: this.props.chartWater.paliwo,
            },
          ]}}
          options={{
            title:{
              display:true,
              text:'test',
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            },
            scales:{
              x:{type:'time'},
              y:{type:'linear'},
            },
            plugins:{legend:{ onClick: () => {}}},
          }}
        />
      </div>
    );
  }
}
export default ComTabWaterChart;
