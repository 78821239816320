import React from 'react';
import {Line} from 'react-chartjs-2';
  
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const chartColors = 
[
'rgb(77, 201, 246)',
'rgb(243, 52, 52)',
'rgb(238, 224, 23)',
'rgb(0, 169, 80)',
'rgb(157, 52, 243)',
'rgb(246, 112, 25)',
'hsl(198, 73%, 32%)',
'rgb(245, 55, 148)',
'rgb(172, 194, 54)',
'rgb(133, 73, 186)'
];

class ComTabEnergyChart extends React.Component {
  render() {
    return (
      <div>
        <Line
          data={{  
            datasets: [
              {
                id: "1",
                label: 'Boiler',
                fill: false,
                hidden: !this.props.output1Visible,
                backgroundColor: chartColors[0],
                borderColor: chartColors[0],
                borderWidth: 2,
                data: this.props.chartEnergy.output1
              },
              {
                id: "2",
                label: 'Klimatyzacja',
                fill: false,
                hidden: !this.props.output2Visible,
                backgroundColor: chartColors[1],
                borderColor: chartColors[1],
                borderWidth: 2,
                data: this.props.chartEnergy.output2
              },
              {
                id: "3",
                label: 'Sypialnia P',
                fill: false,
                hidden: !this.props.output3Visible,
                backgroundColor: chartColors[2],
                borderColor: chartColors[2],
                borderWidth: 2,
                data: this.props.chartEnergy.output3
              },
              {
                id: "4",
                label: 'Sypialnia L',
                fill: false,
                hidden: !this.props.output4Visible,
                backgroundColor: chartColors[3],
                borderColor: chartColors[3],
                borderWidth: 2,
                data: this.props.chartEnergy.output4
              },
              {
                id: "5",
                label: 'Kuchnia',
                fill: false,
                hidden: !this.props.output5Visible,
                backgroundColor: chartColors[4],
                borderColor: chartColors[4],
                borderWidth: 2,
                data: this.props.chartEnergy.output5
              },
              {
                id: "6",
                label: 'AGD',
                fill: false,
                hidden: !this.props.output6Visible,
                backgroundColor: chartColors[5],
                borderColor: chartColors[5],
                borderWidth: 2,
                data: this.props.chartEnergy.output6
              },
              {
                id: "7",
                label: 'Taras',
                fill: false,
                hidden: !this.props.output7Visible,
                backgroundColor: chartColors[6],
                borderColor: chartColors[6],
                borderWidth: 2,
                data: this.props.chartEnergy.output7
              },
              {
                id: "8",
                label: 'Pompy',
                fill: false,
                hidden: !this.props.output8Visible,
                backgroundColor: chartColors[7],
                borderColor: chartColors[7],
                borderWidth: 2,
                data: this.props.chartEnergy.output8
              },
              {
                id: "9",
                label: 'Wyjście 9',
                fill: false,
                hidden: !this.props.output9Visible,
                backgroundColor: chartColors[8],
                borderColor: chartColors[8],
                borderWidth: 2,
                data: this.props.chartEnergy.output9
              },
              {
                id: "10",
                label: 'Wyjście 10',
                fill: false,
                hidden: !this.props.output10Visible,
                backgroundColor: chartColors[9],
                borderColor: chartColors[9],
                borderWidth: 2,
                data: this.props.chartEnergy.output10
              },

            ]}}
          options={{
            title:{
              display:true,
              text:'test',
              fontSize:20
            },
            legend:{
              display:true,
              position:'right',
            },
            scales:{
              x:{type:'time'},
              y:{type:'linear'},
            },
            plugins:{legend:{ onClick: () => {}}},
          }}
        />
      </div>
    );
  }
}
export default ComTabEnergyChart;
