import React from 'react';
import {Navbar,Container,Nav,Dropdown,Badge,Button, Spinner} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BoxArrowRight, Key, Calendar, ArrowClockwise} from 'react-bootstrap-icons';
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import "./style.css"
import 'moment/locale/pl';
import parseISO from 'date-fns/parseISO';
import compareAsc from 'date-fns/compareAsc';
import axios from 'axios';

class comNavbar extends React.Component
{
    state =
    {
        requestSending: false,
        enabledSend: true,
    }

    ready = true;

    render()
    {
        var houseList = this.props.user.availableHouseList.map((item) => <Dropdown.Item href="#" onClick={() => {this.props.setHouse(item.house_id, item.rented, item.house_label)}}>{item.house_label} <Badge bg={item.rented === false ? "secondary" : "success"} pill>{item.rented === false ? "Wolny" : "Wynajęty"}</Badge> </Dropdown.Item>);
        var rentListAutoComplete = this.props.rentHistoryList.map((item) => <Dropdown.Item href="#" onClick={() => {
            this.props.setDateStart(new Date(item.date_start)); 
            this.props.setDateEnd(new Date(item.date_end));
        }}><Calendar/> {item.date_start} - {item.date_end} </Dropdown.Item>);

        return (
            <React.Fragment>
                    <Navbar bg="primary" expand="lg">
                    <Container fluid>
                        <Navbar.Brand style={{color: "white"}} href="#">SeaStead Dashboard</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">

                        </Nav>
                        <Navbar.Text style={{color: "white", paddingRight: "10px"}}>
                            Ostatnia aktualizacja danych: {this.props.actualValues.valdate}
                        </Navbar.Text>
                        <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic-2">
                            {this.props.choosenHouse.house_label + " "} 
                            <Badge bg={this.props.choosenHouse.rented === false ? "secondary" : "success"} pill>{this.props.choosenHouse.rented === false ? "Wolny" : "Wynajęty"}</Badge>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {houseList}
                        </Dropdown.Menu>
                        </Dropdown>
                        
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                            Zakres dat 
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                            {rentListAutoComplete}
                            </Dropdown.Menu>
                        </Dropdown>
                        <DateTime className="DateTimeField" id='dateTimePicker1' value={this.props.date_start_view} closeOnSelect={true} closeOnTab={true} closeOnClickOutside={true} onChange={(time) => {
                        this.props.setDateStart(time._d);

                        console.log(this.props.choosenHouse);
                        console.log(this.props.getDateStart());
                        console.log(this.props.getDateEnd());
                        console.log(parseISO(this.props.getDateStart()));
                        console.log(parseISO(this.props.getDateEnd()));

                        if(compareAsc(parseISO(this.props.getDateStart()), parseISO(this.props.getDateEnd())) === -1)
                        {
                            this.setState({enabledSend: true});
                        }
                        else
                        {
                            this.setState({enabledSend: false});
                        }

                        }}/>
                        <DateTime className="DateTimeField" id='dateTimePicker2' value={this.props.date_end_view} closeOnSelect={true} closeOnTab={true} closeOnClickOutside={true} onChange={(time) => {
                        this.props.setDateEnd(time._d);

                        console.log(this.props.getDateStart());
                        console.log(this.props.getDateEnd());
                        console.log(parseISO(this.props.getDateStart()));
                        console.log(parseISO(this.props.getDateEnd()));

                        if(compareAsc(parseISO(this.props.getDateStart()), parseISO(this.props.getDateEnd())) === -1)
                        {
                            this.setState({enabledSend: true});
                        }
                        else
                        {
                            this.setState({enabledSend: false});
                        }

                        }}/>
                        <Button id="reloadButton" variant="success" disabled={(!this.state.enabledSend)||(this.state.requestSending)} onClick={() => 
                            { 
                                this.setState({requestSending : true});
                                axios.get(`http://backend-seastead.red-electronics.pl/service/wtest`).then(res => {if(res.data==="OK"){if(this.props.callReloadData()===true){this.setState({requestSending : false});}else{this.setState({requestSending : false});};}}).catch(err => {this.setState({requestSending : false}); alert("Nie można nawiązać połączenia z serwerem.");});
                                
                                
                                }}>
                            <Spinner animation="border" size="sm" variant="light" hidden={!this.state.requestSending}/>
                            <ArrowClockwise hidden={this.state.requestSending}/>
                        </Button>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <Button variant="light rounded-circle me-2" style={{padding: "0px", width:"32px", height:"32px", textAlign:"center", fontSize:"large"}}>{this.props.user.login[0].toUpperCase()}</Button>
                                {this.props.user.login}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => {this.props.navigate('/profile')}}><Key/> Zmiana hasła</Dropdown.Item>
                                <hr/>
                                <Dropdown.Item onClick={() => {localStorage.removeItem("token"); window.location.reload();}}><BoxArrowRight/> Wyloguj</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </React.Fragment>
        );
    }
}

export default comNavbar;