import React from 'react';
import {Line} from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

class ComTabResourcesEnvironmentChart extends React.Component 
{
  render() 
  {
    return (
      <div>
        <Line
          data={{  
            datasets: [
            {
              label: "Serwerownia",
              fill: false,
              backgroundColor: 'rgb(13, 110, 253)',
              borderColor: 'rgb(13, 110, 253)',
              borderWidth: 2,
              data: this.props.chartData.Salon,
            },
            {
              label: "Pływaki",
              fill: false,
              backgroundColor: 'rgb(77, 201, 246)',
              borderColor: 'rgb(77, 201, 246)',
              borderWidth: 2,
              data: this.props.chartData.Kuchnia,
            },
            {
              label: "Taras",
              fill: false,
              backgroundColor: 'rgb(238, 224, 23)',
              borderColor: 'rgb(238, 224, 23)',
              borderWidth: 2,
              data: this.props.chartData.Taras,
            },
          ]}}
          options={{
            title:{
              display:true,
              text:this.props.chartLabel,
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            },
            scales:{
              x:{type:'time'},
              y:{type:'linear'},
            }
          }}
        />
      </div>
    );
  }
}
export default ComTabResourcesEnvironmentChart;
