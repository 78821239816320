import React from 'react';
import {Container,Tab,Tabs} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ComTabEnergy from './ComTabEnergy';
import ComTabResources from './ComTabResources';
import ComTabRent from './ComTabRent';
import ComAdmin from './ComAdmin';
//import ComTabAlerts from './ComTabAlerts';
import ComTabNotifications from './ComTabNotifications';

class ComMenu extends React.Component
{
    render()
    {
        return(
            <React.Fragment>
                <Tabs defaultActiveKey="pageEnergy" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="pageEnergy" title={"Energia"}>
                        <Container>
                            <ComTabEnergy changeChoosenHouseData = {this.props.changeChoosenHouseData} chartEnergy={this.props.chartEnergy} choosenHouse = {this.props.choosenHouse}/>
                        </Container>
                    </Tab>
                    <Tab eventKey="pageResources" title="Zasoby">
                        <Container>
                            <ComTabResources changeChoosenHouseData = {this.props.changeChoosenHouseData} chartWater={this.props.chartWater} chartTemp={this.props.chartTemp} chartHumid={this.props.chartHumid} chartPress={this.props.chartPress} actualValues = {this.props.actualValues} choosenHouse = {this.props.choosenHouse} navigate = {this.props.navigate}/>
                        </Container>
                    </Tab>
                    <Tab eventKey="pageRent" title="Wynajem">
                        <Container>
                            <ComTabRent rentHistoryList={this.props.rentHistoryList} user={this.props.constData.user} house_id={this.props.constData.house_id} callReloadData={this.props.callReloadData} navigate = {this.props.navigate}/>
                        </Container>
                    </Tab>
                    <Tab eventKey="pageNotifications" title="Powiadomienia">
                        <Container>
                            <ComTabNotifications changeChoosenHouseData = {this.props.changeChoosenHouseData} choosenHouse = {this.props.choosenHouse} user={this.props.constData.user} house_id={this.props.constData.house_id} callReloadData={this.props.callReloadData} navigate = {this.props.navigate}/>
                        </Container>
                    </Tab>
{/*                     <Tab eventKey="pageAdmin" title="Administracja">
                        <Container>
                            <ComAdmin />
                        </Container>
                    </Tab> */}
                </Tabs>
            </React.Fragment>
        );
    }
}

export default ComMenu;